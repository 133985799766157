.languageInput {
    color: var(--footer-language-input-text-color);
    font-size: var(--footer-font-size);
    cursor: pointer;
}

.languageSelectorContainer {
    text-align: center;
    padding: 0 !important;
}

.langBtn {
    all: initial !important;
}